import request from '@/utils/request'

// 分享H5页面内容 (柿子)
export function shareContent(data) {
    return request({
        url: '/learn/share/course/content',
        method: 'POST',
        data
    })
}

// 分享H5页面内容 (向知)
export function shareCourseDetail(data) {
    return request({
        url: '/config/common/course_detail',
        method: 'POST',
        data
    })
}