<template>
  <div class="Share">
    <div class="top">
      <video
        :src="content.videoLink"
        controls
        disablePictureInPicture
        autoplay
        muted
        width="100%"
        height="200px"
        ref="example_video"
        id="example_video"
      />
    </div>
    <div class="main">
      <div class="main1">{{ content.courseName }}</div>
      <div class="main2">
        <span @click="course()" :class="type === 1 ? 'active' : ''"
          >课程介绍</span
        >&emsp;
        <span @click="chapter()" :class="type === 2 ? 'active' : ''"
          >章节列表</span
        >
      </div>
      <div
        class="main3"
        style="overflow: hidden; overflow-y: auto"
        :style="{ height: screenHeight + 'vh' }"
      >
        <div v-if="type === 1">
          <p v-html="content.courseDesc" class="courseDesc"></p>
        </div>
        <div v-if="type === 2">
          <div
            v-if="
              (query.type &&
                query.msg.split(':')[2] == 0 &&
                !content.sectionChapters) ||
              (query.type &&
                query.msg.split(':')[2] == 1 &&
                !content.chapters) ||
              (!query.type && !content.chapters.length)
            "
          >
            <div
              class="flex"
              style="height: 30vh"
              v-if="query.type && query.type == 1"
            >
              <img src="@/assets/Share/meiyou.png" />
            </div>
            <div class="flex" style="height: 30vh" v-if="!query.type">
              <img src="@/assets/Share/logoIcon.png" />&emsp;
              <span>暂无章节信息</span>
            </div>
          </div>
          <div
            v-if="query.msg.split(':')[2] == 0 && query.type && query.type == 1"
          >
            <div v-for="(item, i) in content.sectionChapters" :key="i">
              <div
                style="
                  padding: 10px 15px;
                  font-weight: 600;
                  background-image: linear-gradient(to right, #f6f7f9, #d6d7d9);
                "
                :style="{ 'margin-top': i > 0 ? '30px' : '' }"
              >
                {{ item.sectionName }}
              </div>
              <ul>
                <li
                  v-for="(chapter, j) in item.chapters"
                  :key="j"
                  @click="Click(j)"
                >
                  <div style="position: relative; width: 232px">
                    <div class="chapter">{{ chapter.name }}</div>
                    <div style="position: absolute; bottom: 0">
                      <div class="flex">
                        <div class="icon">
                          <img src="@/assets/Share/icon1.png" />
                          <span>{{ stohs(chapter.videoDuration) }}</span>
                        </div>
                        <div class="icon">
                          <img src="@/assets/Share/icon2.png" />
                          <span>{{ chapter.playedCount }}</span>
                        </div>
                        <div class="icon">
                          <img src="@/assets/Share/icon3.png" />
                          <span>{{ chapter.commentsCount }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <img
                      src="@/assets/Share/icon4.png"
                      style="width: 22px; height: 22px"
                      v-if="i === 0 && j === 0"
                    />
                    <img
                      src="@/assets/Share/icon5.png"
                      style="width: 18px; height: 22px"
                      v-else
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="
              (query.msg.split(':')[2] == 1 && query.type && query.type == 1) ||
              !query.type
            "
          >
            <ul>
              <li
                v-for="(item, index) in content.chapters"
                :key="index"
                @click="Click(index)"
              >
                <div style="position: relative; width: 232px">
                  <div class="chapter">{{ item.name }}</div>
                  <div style="position: absolute; bottom: 0">
                    <div class="flex">
                      <div class="icon">
                        <img src="@/assets/Share/icon1.png" />
                        <span>{{ stohs(item.videoDuration) }}</span>
                      </div>
                      <div class="icon">
                        <img src="@/assets/Share/icon2.png" />
                        <span>{{ item.playedCount }}</span>
                      </div>
                      <div class="icon">
                        <img src="@/assets/Share/icon3.png" />
                        <span>{{ item.commentsCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <img
                    src="@/assets/Share/icon4.png"
                    style="width: 22px; height: 22px"
                    v-if="index === 0"
                  />
                  <img
                    src="@/assets/Share/icon5.png"
                    style="width: 18px; height: 22px"
                    v-else
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" @click="download()">
      <div class="bottom_1 flex" v-if="query.type && query.type == 1">
        <img src="@/assets/icon_logo.png" class="xzImg" />
        <span>向知APP，为传播知识而来</span>
      </div>
      <div class="bottom_1 flex" v-else>
        <span style="visibility: hidden">0</span>
        <img src="@/assets/Share/logo.png" class="xzImgNo" />
      </div>
      <div class="bottom_2 flex">
        <span>{{
          query.type && query.type == 1 ? "点击下载" : "公众号购买课程"
        }}</span
        >&emsp;
        <img src="@/assets/Share/icon6.png" style="width: 32px; height: 32px" />
      </div>
    </div>
    <div class="image" v-if="!query.type && codeVisible">
      <img src="@/assets/Share/code.png" style="width: 289px; height: 335px" />
      <img
        src="@/assets/Share/off.png"
        style="width: 32px; height: 32px"
        class="off"
        @click="codeVisible = false"
      />
    </div>
  </div>
</template>

<script>
import { shareContent, shareCourseDetail } from "@/api/Persimmon.js";
import { stohs } from "@/utils/date.js";
export default {
  name: "Share",
  data() {
    return {
      screenHeight: window.screen.height * 0.062,
      query: this.$route.query,
      type: 1,
      codeVisible: false,
      stohs,
      content: {
        courseName: "",
        courseDesc: "",
        courseCoverImg: "",
        asd: "",
        qrCode: "",
        videoLink: "",
        chapters: [],
        sectionChapters: [],
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    //视频自动播放
    let video = document.getElementById("example_video");
    if (typeof WeixinJSBrdgeReady == "object") {
      video.play();
    } else {
      if (document.addEventListener) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          function () {
            video.play();
          },
          false
        );
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", function () {
          video.play();
        });
        document.attachEvent("onWeixinJSBridgeReady", function () {
          video.play();
        });
      }
    }
  },
  methods: {
    Click(index) {
      if (!index) return;
      this.$message.closeAll();
      this.$message.error({
        message: "查看更多章节视频，请登录向知App！",
        offset: 230,
      });
    },
    course() {
      this.type = 1;
    },
    chapter() {
      this.type = 2;
    },
    async init() {
      const res =
        this.query.type && this.query.type == 1
          ? await shareCourseDetail({ msg: this.query.msg })
          : await shareContent({ msg: this.query.msg });
      const { data, success } = res.data;
      if (success) this.content = data;
    },
    download() {
      if (this.query.type && this.query.type == 1) {
        const u = navigator.userAgent;
        const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isAndroid) {
          window.location.href = "xz://openapp/welcome";
          setTimeout(() => {
            const hidden =
              window.document.hidden ||
              window.document.mozHidden ||
              window.document.msHidden ||
              window.document.webkitHidden;
            if (typeof hidden === "undefined" || hidden === false) {
              // 应用宝下载地址
              window.location.href =
                "https://web.lumibayedu.com/api/config/apk";
            }
          }, 2000);
        } else if (isiOS) {
          window.location.href = "xzlumibay://";
          this.downloader = setTimeout(() => {
            const hidden =
              window.document.hidden ||
              window.document.mozHidden ||
              window.document.msHidden ||
              window.document.webkitHidden;
            if (typeof hidden === "undefined" || hidden === false) {
              // 应用宝下载地址
              window.location.href =
                "https://itunes.apple.com/cn/app/id1547402998";
            }
          }, 3000);
        }
      } else {
        this.codeVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Share {
  position: relative;
  padding: 10px 15px;
}
video {
  border-radius: 10px;
}
.main {
  text-align: justify;
  padding: 10px 0;
}
.main1 {
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}
.main2 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  text-align: center;
  margin: 24px 0 21px;
}
.active {
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  background: url("~@/assets/Share/icon7.png") no-repeat center/18px;
}
::v-deep .courseDesc img {
  max-width: 100%;
  max-height: 100%;
}
.main3 li {
  width: 100%;
  height: 97px;
  background: #f6f7f9;
  border-radius: 6px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px 18px 10px 14px;
}
.main3 li .chapter {
  height: 43px;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.main3 li .icon {
  display: flex;
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  width: 55px;
}
.main3 li .icon img {
  width: 15px;
  height: 15px;
}
.main3 li .icon span {
  margin: 0 21px 0 3px;
}
.bottom {
  position: fixed;
  left: 15px;
  right: 15px;
  bottom: 0;
  height: 70px;
  line-height: 70px;
  background: #fff;
}
.bottom_1 {
  float: left;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.xzImgNo {
  width: 114px;
  height: 26px;
}
.xzImg {
  width: 36px;
  height: 37px;
  margin-right: 8px;
}
.bottom_2 {
  float: right;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fd6742;
}
.image {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -144.5px;
  margin-top: -167.5px;
}
.image .off {
  position: absolute;
  top: 95%;
  left: 50%;
  margin-left: -16px;
}
</style>
